// The material design colors (except white and black).
const colors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#607D8B',
  '#9E9E9E'
]

function getShuffledArray (arr) {
  const newArr = arr.slice()
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]]
  }
  return newArr
}

function getRandomColor () {
  return colors[Math.floor(Math.random() * colors.length)]
}

function getRandomColors (amount) {
  let result = []
  let remaining = amount

  // Add randomized color arrays until a full array no longer fits in the remaining amount.
  while (remaining > colors.length) {
    result = result.concat(getShuffledArray(colors))
    remaining = remaining - colors.length
  }

  // Add remaining amount.
  const remainingItems = getShuffledArray(colors).slice(0, remaining)
  return result.concat(remainingItems)
}

export default {
  getRandomColor,
  getRandomColors
}
