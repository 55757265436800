<template>
  <apexchart
    :ref="chartRef"
    :width="width"
    :height="height"
    type="scatter"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import { mapState } from 'vuex'
import colorHelper from '@/components/helpers/colorHelper'

export default {
  props: {
    series: { type: Array, default: () => [] },
    title: { type: String, default: undefined },
    width: { type: String, default: '100%' },
    height: { type: Number, default: 500 },
    colors: { type: Array, default: undefined },
    tooltipPrecision: { type: Number, default: 0 },
    yAxisTitle: { type: String, default: undefined }
  },
  data () {
    return {
      chartRef: `scatter-chart-${this.$uuid.v4()}`
    }
  },
  computed: {
    ...mapState('preferences', ['darkMode', 'showAdvancedChartControls']),
    ...mapState('languages', ['currentLanguageCode']),
    chartOptions () {
      // We have to put the current scope in a variable to prevent scope issues inside the formatter functions.
      const vm = this

      const options = {
        chart: {
          fontFamily: 'inherit',
          animations: {
            dynamicAnimation: {
              enabled: false
            }
          },
          toolbar: {
            show: this.showAdvancedChartControls
          },
          zoom: {
            enabled: this.showAdvancedChartControls
          }
        },
        theme: {
          mode: this.darkMode ? 'dark' : 'light'
        },
        colors: this.colors ? this.colors : colorHelper.getRandomColors(this.series.length),
        stroke: {
          colors: this.darkMode ? ['#1E1E1E'] : ['#E0E0E0']
        },
        legend: {
          position: 'bottom'
        },
        tooltip: {
          y: {
            // Ensures all tooltips are in the preferred precision.
            formatter: function (val, index) {
              const multiplier = Math.pow(10, vm.tooltipPrecision || 0)
              const roundedValue = Math.round(val * multiplier) / multiplier
              return roundedValue.toLocaleString(vm.currentLanguageCode, {
                minimumFractionDigits: vm.tooltipPrecision,
                maximumFractionDigits: vm.tooltipPrecision
              })
            }
          },
          x: {
            // By default the tooltip only shows the date, with this it also shows time.
            format: 'd MMM (HH:mm)'
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            // Fixes an issue where dates are shown in the local (browser/computer) timezone, instead of the actual supplied date(s).
            datetimeUTC: false
          }
        },
        yaxis: {
          labels: {
            // Ensures all y-axis labels are integer values (no decimals).
            formatter: function (val, index) {
              return parseInt(val).toLocaleString(vm.currentLanguageCode)
            }
          },
          title: {
            text: this.yAxisTitle,
            style: {
              fontFamily: 'inherit'
            }
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        title: {
          text: this.title,
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
            fontFamily: 'inherit'
          }
        }
      }

      return options
    }
  },
  watch: {
    currentLanguageCode: {
      immediate: true,
      handler (newValue, oldValue) {
        // Update chart locale when the language changes.
        if (this.$refs && this.$refs[this.chartRef]) {
          this.$refs[this.chartRef].chart.setLocale(newValue)
        }
      }
    }
  },
  mounted () {
    // Update chart locale to the current language.
    this.$refs[this.chartRef].chart.setLocale(this.currentLanguageCode)
  }
}
</script>
