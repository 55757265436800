<template>
  <PieChart
    :series="series"
    :labels="labels"
    :title="$t('charts.error-occurrence.title')"
    :show-legend="false"
  />
</template>

<script>
import PieChart from '@/components/charts/PieChart'

export default {
  components: { PieChart },
  props: {
    chartModel: { type: Array, default: null },
    loading: { type: Boolean, default: false }
  },
  computed: {
    series () {
      return this.chartModel ? this.chartModel.map(x => x.occurrences) : []
    },
    labels () {
      return this.chartModel ? this.chartModel.map(x => x.errorName) : []
    }
  }
}
</script>
