<template>
  <ScatterChart
    :series="series"
    :title="$t(titleKey)"
    :yAxisTitle="$t(preferredUnit.longTranslationKey)"
    :tooltipPrecision="preferredUnit.displayPrecision"
  />
</template>

<script>
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'
import ScatterChart from '@/components/charts/ScatterChart'

export default {
  components: { ScatterChart },
  props: {
    chartModel: { type: Array, default: null },
    fuelType: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('fuelTypes', ['getPreferredUnitByFuelType']),
    preferredUnit () {
      return this.getPreferredUnitByFuelType(this.fuelType)
    },
    titleKey () {
      switch (this.fuelType.name) {
        case 'diesel':
          return 'charts.vehicle-session-history.diesel-title'
        case 'lng':
          return 'charts.vehicle-session-history.lng-title'
        case 'power':
          return 'charts.vehicle-session-history.power-title'
        default:
          return null
      }
    },
    series () {
      if (!this.chartModel) return []
      const result = this.chartModel.map(vehicle => {
        return {
          name: vehicle.vehicleName ? vehicle.vehicleName : vehicle.vehicleUniqueIdentifier,
          data: vehicle.sessions.map(session => {
            return [
              // Creating the moment will automatically apply moment's default timezone (which is the user's preferred timezone).
              moment(session.start)
                // Formatting it without timezone will allow ApexCharts to use it correctly,
                // because ApexCharts can only work in with utc or the local (browser/computer) timezone.
                .format('YYYY-MM-DDTHH:mm:ss'),
              this.preferredUnit.convert(session.quantityFuelled)
            ]
          })
        }
      })

      return result
    }
  }
}
</script>
