<template>
  <apexchart
    :ref="chartRef"
    :width="width"
    :height="height"
    type="line"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import { mapState } from 'vuex'
import colorHelper from '@/components/helpers/colorHelper'

export default {
  props: {
    chartModel: { type: Array, default: () => [] },
    width: { type: String, default: '100%' },
    height: { type: Number, default: 300 },
    /**
     * An array filled with objects like: { systemId, color }.
     */
    colors: { type: Array, default: undefined }
  },
  data () {
    return {
      chartRef: `sessions-per-system-chart-${this.$uuid.v4()}`
    }
  },
  computed: {
    ...mapState('preferences', ['darkMode', 'showAdvancedChartControls']),
    ...mapState('languages', ['currentLanguageCode']),
    randomColors () {
      return colorHelper.getRandomColors(this.series.length)
    },
    chartColors () {
      return this.colors
        ? this.chartModel.map(s => this.colors.find(c => c.systemId === s.systemId).color)
        : this.randomColors
    },
    series () {
      if (!this.chartModel) return []

      return this.chartModel.map(system => ({
        name: system.systemName,
        type: 'line',
        data: system.days.map(day => [day.dayDate, day.amountOfSessions])
      }))
    },
    chartOptions () {
      const options = {
        chart: {
          fontFamily: 'inherit',
          toolbar: {
            show: this.showAdvancedChartControls
          },
          zoom: {
            enabled: this.showAdvancedChartControls
          }
        },
        theme: {
          mode: this.darkMode ? 'dark' : 'light'
        },
        colors: this.chartColors,
        legend: {
          position: 'bottom'
        },
        stroke: {
          curve: 'straight',
          width: 3
        },
        xaxis: {
          type: 'datetime',
          labels: {
            // Fixes an issue where dates are shown in the local (browser/computer) timezone, instead of the actual supplied date(s).
            datetimeUTC: false
          }
        },
        yaxis: {
          title: {
            text: this.$t('common.sessions'),
            style: {
              fontFamily: 'inherit'
            }
          },
          min: 0,
          labels: {
            // Ensures all y-axis labels are integer values, because you can't do 0.4 fuelling sessions.
            formatter: function (val, index) {
              return parseInt(val)
            }
          }
        },
        title: {
          text: this.$t('charts.sessions-per-system.title'),
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
            fontFamily: 'inherit'
          }
        }
      }

      return options
    }
  },
  watch: {
    currentLanguageCode: {
      immediate: true,
      handler (newValue, oldValue) {
        // Update chart locale when the language changes.
        if (this.$refs && this.$refs[this.chartRef]) {
          this.$refs[this.chartRef].chart.setLocale(newValue)
        }
      }
    }
  },
  mounted () {
    // Update chart locale to the current language.
    this.$refs[this.chartRef].chart.setLocale(this.currentLanguageCode)
  }
}
</script>
