<template>
  <apexchart
    :ref="chartRef"
    :width="width"
    :height="height"
    type="bar"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import colorHelper from '@/components/helpers/colorHelper'

export default {
  props: {
    chartModel: { type: Array, default: () => [] },
    fuelType: { type: Object, required: true },
    width: { type: String, default: '100%' },
    height: { type: Number, default: 300 },
    /**
     * An array filled with objects like: { systemId, color }.
     */
    colors: { type: Array, default: undefined },
    // title: { type: String, default: undefined },
    yAxisTitle: { type: String, default: undefined }
  },
  data () {
    return {
      chartRef: `quantity-fuelled-per-system-chart-${this.$uuid.v4()}`
    }
  },
  computed: {
    ...mapState('preferences', ['darkMode', 'showAdvancedChartControls']),
    ...mapState('languages', ['currentLanguageCode']),
    ...mapGetters('fuelTypes', ['getPreferredUnitByFuelType']),
    preferredUnit () {
      return this.getPreferredUnitByFuelType(this.fuelType)
    },
    series () {
      if (!this.chartModel) return []

      return [{
        name: this.$t(this.preferredUnit.longTranslationKey),
        data: this.chartModel.map(s => this.preferredUnit.convert(s.quantityFuelled))
      }]
    },
    categories () {
      return this.chartModel ? this.chartModel.map(s => s.systemName) : []
    },
    randomColors () {
      return colorHelper.getRandomColors(this.categories.length)
    },
    chartColors () {
      return this.colors
        ? this.chartModel.map(s => this.colors.find(c => c.systemId === s.systemId).color)
        : this.randomColors
    },
    titleKey () {
      switch (this.fuelType.name) {
        case 'lng':
          return 'charts.quantity-fuelled-per-system.lng-title'
        case 'power':
          return 'charts.quantity-fuelled-per-system.power-title'
        case 'diesel':
          return 'charts.quantity-fuelled-per-system.diesel-title'
        default:
          return null
      }
    },
    chartOptions () {
      // We have to put the current scope in a variable to prevent scope issues inside the formatter functions.
      const vm = this

      const options = {
        chart: {
          fontFamily: 'inherit',
          toolbar: {
            show: this.showAdvancedChartControls
          }
        },
        theme: {
          mode: this.darkMode ? 'dark' : 'light'
        },
        colors: this.chartColors,
        xaxis: {
          categories: this.categories,
          labels: {
            style: {
              colors: this.chartColors
            }
          }
        },
        yaxis: {
          labels: {
            // Ensures all y-axis labels are integer values (no decimals).
            formatter: function (val, index) {
              return parseInt(val).toLocaleString(vm.currentLanguageCode)
            }
          },
          title: {
            text: this.$t(this.preferredUnit.longTranslationKey),
            style: {
              fontFamily: 'inherit'
            }
          }
        },
        dataLabels: {
          // Ensures all data labels are integer values (no decimals).
          formatter: function (val, index) {
            return parseInt(val).toLocaleString(vm.currentLanguageCode)
          }
        },
        tooltip: {
          y: {
            // Ensures all tooltips are in the preferred precision.
            formatter: function (val, index) {
              const precision = vm.preferredUnit.displayPrecision
              const multiplier = Math.pow(10, precision || 0)
              const roundedValue = Math.round(val * multiplier) / multiplier
              return roundedValue.toLocaleString(vm.currentLanguageCode, {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision
              })
            }
          }
        },
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            distributed: true
          }
        },
        title: {
          text: this.$t(this.titleKey),
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
            fontFamily: 'inherit'
          }
        }
      }

      return options
    }
  },
  watch: {
    currentLanguageCode: {
      immediate: true,
      handler (newValue, oldValue) {
        // Update chart locale when the language changes.
        if (this.$refs && this.$refs[this.chartRef]) {
          this.$refs[this.chartRef].chart.setLocale(newValue)
        }
      }
    }
  },
  mounted () {
    // Update chart locale to the current language.
    this.$refs[this.chartRef].chart.setLocale(this.currentLanguageCode)
  }
}
</script>
