<template>
  <apexchart
    :width="width"
    :height="height"
    type="pie"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import { mapState } from 'vuex'
import colorHelper from '@/components/helpers/colorHelper'

export default {
  props: {
    series: { type: Array, default: () => [] },
    labels: { type: Array, default: () => [] },
    title: { type: String, default: undefined },
    width: { type: String, default: '100%' },
    height: { type: Number, default: 300 },
    colors: { type: Array, default: undefined },
    showLegend: { type: Boolean, default: true }
  },
  computed: {
    ...mapState('preferences', ['darkMode', 'showAdvancedChartControls']),
    chartOptions () {
      const options = {
        chart: {
          fontFamily: 'inherit',
          toolbar: {
            show: this.showAdvancedChartControls
          }
        },
        theme: {
          mode: this.darkMode ? 'dark' : 'light'
        },
        colors: this.colors ? this.colors : colorHelper.getRandomColors(this.labels.length),
        stroke: {
          colors: this.darkMode ? ['#1E1E1E'] : ['#E0E0E0']
        },
        legend: {
          position: 'bottom',
          show: this.showLegend
        },
        title: {
          text: this.title,
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
            fontFamily: 'inherit'
          }
        },
        labels: this.labels
      }

      return options
    }
  }
}
</script>
