<template>
  <div>
    <!-- Filters for small devices -->
    <v-expansion-panels v-if="!$vuetify.breakpoint.mdAndUp" class="mb-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t('common.filters') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SessionsFilter
            @searchRequested="onSearchRequested"
            @filterChanged="onFilterChanged"
            :loading="loading"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <!-- Content -->
      <v-col cols="12" md="8" lg="9">
        <v-row>
          <v-col cols="12" sm="6" xl="4" v-if="successrateModel">
            <SuccessRateChart
              :chart-model="successrateModel"
            />
          </v-col>
          <v-col cols="12" sm="6" xl="4" v-if="errorOccurrenceModel && errorOccurrenceModel.length > 0">
            <ErrorOccurrenceChart
              :chart-model="errorOccurrenceModel"
            />
          </v-col>
          <v-col cols="12" sm="12" xl="6" v-if="sessionDatesPerSystemModel && sessionDatesPerSystemModel.length > 0">
            <SessionsPerSystemChart
              :chart-model="sessionDatesPerSystemModel"
              :colors="colorPerSystem"
            />
          </v-col>
          <v-col cols="12" sm="12" xl="6" v-if="dieselFuelledPerSystemModel && dieselFuelledPerSystemModel.length > 0">
            <QuantityFuelledPerSystemChart
              :chart-model="dieselFuelledPerSystemModel"
              :fuel-type="getFuelTypeByName('diesel')"
              :colors="colorPerSystem"
            />
          </v-col>
          <v-col cols="12" sm="12" xl="6" v-if="lngFuelledPerSystemModel && lngFuelledPerSystemModel.length > 0">
            <QuantityFuelledPerSystemChart
              :chart-model="lngFuelledPerSystemModel"
              :fuel-type="getFuelTypeByName('lng')"
              :colors="colorPerSystem"
            />
          </v-col>
          <v-col cols="12" sm="12" xl="6" v-if="powerFuelledPerSystemModel && powerFuelledPerSystemModel.length > 0">
            <QuantityFuelledPerSystemChart
              :chart-model="powerFuelledPerSystemModel"
              :fuel-type="getFuelTypeByName('power')"
              :colors="colorPerSystem"
            />
          </v-col>
          <v-col cols="12" sm="12" xl="8" v-if="dieselVehicleSessionHistoryModel && dieselVehicleSessionHistoryModel.length > 0">
            <VehicleSessionHistoryChart
              :chart-model="dieselVehicleSessionHistoryModel"
              :fuel-type="getFuelTypeByName('diesel')"
            />
          </v-col>
          <v-col cols="12" sm="12" xl="8" v-if="lngVehicleSessionHistoryModel && lngVehicleSessionHistoryModel.length > 0">
            <VehicleSessionHistoryChart
              :chart-model="lngVehicleSessionHistoryModel"
              :fuel-type="getFuelTypeByName('lng')"
            />
          </v-col>
          <v-col cols="12" sm="12" xl="8" v-if="powerVehicleSessionHistoryModel && powerVehicleSessionHistoryModel.length > 0">
            <VehicleSessionHistoryChart
              :chart-model="powerVehicleSessionHistoryModel"
              :fuel-type="getFuelTypeByName('power')"
            />
          </v-col>
        </v-row>
      </v-col>

      <!-- Filters for medium and large devices -->
      <v-col md="4" lg="3" v-if="$vuetify.breakpoint.mdAndUp">
        <Block :title="$t('common.filters')">
          <SessionsFilter
            @searchRequested="onSearchRequested"
            @filterChanged="onFilterChanged"
            :loading="loading"
          />
        </Block>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'
import Block from '@/components/Block'
import colorHelper from '@/components/helpers/colorHelper'
import ErrorOccurrenceChart from '@/components/charts/ErrorOccurrenceChart'
import QuantityFuelledPerSystemChart from '@/components/charts/QuantityFuelledPerSystemChart'
import SessionsFilter from '@/components/filters/SessionsFilter'
import SessionsPerSystemChart from '@/components/charts/SessionsPerSystemChart'
import SuccessRateChart from '@/components/charts/SuccessRateChart'
import VehicleSessionHistoryChart from '@/components/charts/VehicleSessionHistoryChart'

export default {
  components: {
    Block,
    ErrorOccurrenceChart,
    QuantityFuelledPerSystemChart,
    SessionsFilter,
    SessionsPerSystemChart,
    SuccessRateChart,
    VehicleSessionHistoryChart
  },
  data () {
    return {
      latestFilterData: [],
      loading: false,
      successrateModel: null,
      errorOccurrenceModel: null,
      dieselVehicleSessionHistoryModel: null,
      lngVehicleSessionHistoryModel: null,
      powerVehicleSessionHistoryModel: null,
      sessionDatesPerSystemModel: null,
      dieselFuelledPerSystemModel: null,
      lngFuelledPerSystemModel: null,
      powerFuelledPerSystemModel: null,
      colorPerSystem: []
    }
  },
  computed: {
    ...mapGetters('account', ['isAdmin']),
    ...mapGetters('fuelTypes', ['getFuelTypeByName']),
    ...mapState('systems', ['systems']),
    diesel () {
      return this.getFuelTypeByName('diesel')
    },
    lng () {
      return this.getFuelTypeByName('lng')
    },
    power () {
      return this.getFuelTypeByName('power')
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('softwareVersions', ['fetchSoftwareVersions']),
    ...mapActions('drivers', ['fetchDrivers']),
    ...mapActions('projects', ['fetchAllProjects']),
    ...mapActions('systems', ['fetchAllSystems']),
    ...mapActions('vehicles', ['fetchAllVehicles']),
    ...mapActions('errorCauses', ['fetchAllErrorCauses']),
    ...mapActions('errors', ['fetchAllErrors']),
    async onSearchRequested (newFilter) {
      this.latestFilterData = newFilter
      await this.fetchDashboardData(this.latestFilterData)
    },
    onFilterChanged (newFilter) {
      this.latestFilterData = newFilter
    },
    async fetchDashboardData (filter) {
      this.loading = true
      try {
        const requests = [
          axios.post('statistics/successrate', { ...filter }),
          axios.post('statistics/error-occurrence', { ...filter }),
          axios.post('statistics/vehicle-sessions', { fuelTypes: [this.diesel.value], ...filter }),
          axios.post('statistics/vehicle-sessions', { fuelTypes: [this.lng.value], ...filter }),
          axios.post('statistics/vehicle-sessions', { fuelTypes: [this.power.value], ...filter }),
          axios.post('statistics/session-dates-per-system', { ...filter }),
          axios.post('statistics/quantity-fuelled-per-system', { ...filter })
        ]
        const [
          successrateResponse,
          errorOccurrenceResponse,
          dieselVehicleSessionHistoryResponse,
          lngVehicleSessionHistoryResponse,
          powerVehicleSessionHistoryResponse,
          sessionDatesPerSystemResponse,
          quantityFuelledPerSystemResponse
        ] = await Promise.all(requests)

        // Only change the system colors if the amount of systems displayed has changed.
        const amountOfSystems = sessionDatesPerSystemResponse.data.length
        if (this.colorPerSystem.length !== amountOfSystems) {
          const colors = colorHelper.getRandomColors(amountOfSystems)
          this.colorPerSystem = sessionDatesPerSystemResponse.data.map((item, index) => ({ systemId: item.systemId, color: colors[index] }))
        }

        this.successrateModel = successrateResponse.data
        this.errorOccurrenceModel = errorOccurrenceResponse.data
        this.dieselVehicleSessionHistoryModel = dieselVehicleSessionHistoryResponse.data
        this.lngVehicleSessionHistoryModel = lngVehicleSessionHistoryResponse.data
        this.powerVehicleSessionHistoryModel = powerVehicleSessionHistoryResponse.data
        this.sessionDatesPerSystemModel = sessionDatesPerSystemResponse.data
        this.dieselFuelledPerSystemModel = quantityFuelledPerSystemResponse.data.filter(s => s.fuelType === this.diesel.value)
        this.lngFuelledPerSystemModel = quantityFuelledPerSystemResponse.data.filter(s => s.fuelType === this.lng.value)
        this.powerFuelledPerSystemModel = quantityFuelledPerSystemResponse.data.filter(s => s.fuelType === this.power.value)
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.latestFilterData = this.$store.state.sessionFilter.sessionFilter
    const requests = [
      this.fetchDashboardData(this.latestFilterData),
      this.fetchAllSystems(),
      this.fetchAllVehicles(),
      this.fetchDrivers()
    ]

    if (this.isAdmin) {
      requests.push([
        this.fetchAllErrors(),
        this.fetchAllProjects(),
        this.fetchSoftwareVersions(),
        this.fetchAllErrorCauses()
      ])
    }

    await Promise.all(requests)
  }
}
</script>
