<template>
  <PieChart
    :series="series"
    :labels="labels"
    :colors="colors"
    :title="$t('charts.success-rate.title')"
    :show-legend="false"
  />
</template>

<script>
import PieChart from '@/components/charts/PieChart'

export default {
  components: { PieChart },
  props: {
    chartModel: { type: Object, default: null },
    loading: { type: Boolean, default: false }
  },
  computed: {
    series () {
      return this.chartModel ? [this.chartModel.successes, this.chartModel.recoveries, this.chartModel.errors] : []
    },
    colors () {
      return [this.$vuetify.theme.currentTheme.success, this.$vuetify.theme.currentTheme.warning, this.$vuetify.theme.currentTheme.error]
    },
    labels () {
      return [
        this.$t('charts.success-rate.labels.succeeded'),
        this.$t('charts.success-rate.labels.recovered'),
        this.$t('charts.success-rate.labels.failed')
      ]
    }
  }
}
</script>
